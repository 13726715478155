// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Page two" />
    <h1>Pagina secreta</h1>
    <p>Aun no hay nada ({props.path})</p>
    <p>Aun no hay nada ({props.location.host})</p>
    <h2>An Unordered HTML List</h2>
    <ul>
      <li>Coffee</li>
      <li>Tea</li>
      <li>Milk</li>
    </ul>
    <h2>An Ordered HTML List</h2>
    <ol>
      <li>Coffee</li>
      <li>Tea</li>
      <li>Milk</li>
    </ol>
    test line
    <blockquote cite="test autor">test quote</blockquote>
    <Link to="/">Volver a inicio</Link>
  </Layout>
)

export default SecondPage
